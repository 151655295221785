<template>
  <el-dialog
    width="40rem"
    :visible="true"
    top="8vh"
    :show-close="false"
    custom-class="them-dialog"
    v-loading="$store.state.requestLoading"
  >
    <div slot="title">
      {{title}}
    </div>
    <el-form :model="formData" ref="formData" label-width="6rem" :rules="rules">
      <el-form-item label="姓名:" prop="name">
        <el-input
          clearable
          size="medium"
          v-model="formData.name"
          placeholder="请输入姓名"
          style="width: 18rem;"
        ></el-input>
      </el-form-item>
      <el-form-item label="部门:" prop="departmentId" >
        <el-select
          clearable
          size="medium"
          v-model="formData.departmentId"
          placeholder="请选择部门"
          style="width: 18rem;"
          v-if='opts.length>0'
          @visible-change="setChecked"
        >
          <el-option
            v-for="(item, index) in opts"
            :key="index"
            :value="item.value"
            :label="item.label"
          >
            <span v-if='item.level===1' style="width: 100%;display: inline-block;"> <el-checkbox v-model="item.checked" style="width: 100%;">{{item.label}}</el-checkbox></span>
            <span v-if='item.level===2' style="padding-left: 2rem;width: 100%;display: inline-block;"> <el-checkbox v-model="item.checked" style="width: 100%;">{{item.label}}</el-checkbox></span>
            <span v-if='item.level===3' style="padding-left: 4rem;width: 100%;display: inline-block;"> <el-checkbox v-model="item.checked" style="width: 100%;">{{item.label}}</el-checkbox></span>
          </el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="岗位:" prop="dictId">
        <el-select
          clearable
          size="medium"
          v-model="formData.dictId"
          placeholder="请选择岗位"
          style="width: 18rem;"
        >
          <el-option
            v-for="(item, index) in jobs"
            :key="index"
            :label="item.dictValue"
            :value="item.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色:" prop="roleIds">
        <el-checkbox-group
          v-model="formData.roleIds"
          style="line-height: 20px;padding-top: 11px;"
        >
          <el-checkbox v-for="item in roles " :label="item.roleId"  :value="item.roleId" :key="item.roleId">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="账号:" prop="phone">
        <el-input
          clearable
          size="medium"
          v-model="formData.phone"
          placeholder="请输入11位手机号"
          autoComplete="off"
          style="width: 18rem;"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码:" prop="password">
        <el-input
          clearable
          size="medium"
          style="width: 18rem"
          v-model="formData.password"
          type="password"
          maxlength="20"
          autocomplete="new-password"
          placeholder="请输入字符和数字组成的密码"
        ></el-input>
        <span style="font-size: .8rem;padding-left: .8rem;color: #ccc"
          >未设置密码则只能通过验证码登录</span
        >
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import CryptoJS from 'crypto-js'
export default {
  props: {
    editData: {
      default: null,
      type: Object
    },
    optsOrigin: {
      default: null,
      type: Array
    },
    jobs: {
      default: null,
      type: Array
    },
    roles: {
      default: null,
      type: Array
    }
  },
  data () {
    var validatePhone = (rule, value, callback) => {
      value = value.trim()
      if (!/^1[23456789]\d{9}$/.test(value)) {
        callback(new Error('账号不足11位/账号超过11位/账号仅可包含数字，请重新填写'))
      } else {
        callback()
      }
    }
    var validatePassword = (rule, value, callback) => {
      value = value.trim()
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(value) && value) {
        callback(new Error('密码须包含数字、字母两种元素，且密码位数为6-20位'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        name: '',
        departmentId: '',
        dictId: [],
        roleIds: [],
        phone: '',
        password: ''

      },
      title: '添加账号',
      rules: {
        name: [{ required: true, message: '未输入姓名，请填写后再添加', trigger: 'blur' }],
        departmentId: [
          { required: true, message: '未选择部门，请填写后再添加', trigger: 'blur' }
        ],
        dictId: [{ required: true, message: '未选择岗位，请填写后再添加', trigger: 'blur' }], // 字典id(岗位)
        roleIds: [{ required: true, message: '未选择角色，请填写后再添加', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        password: [
          { validator: validatePassword, trigger: 'blur' }
        ]
      },
      opts: []
    }
  },
  created () {
    if (this.editData) {
      this.formData.departmentId = this.editData.departmentId
      this.formData.dictId = this.editData.dictId
      this.formData.roleIds = this.editData.roleIds
      this.formData.name = this.editData.name
      this.formData.phone = this.editData.phone
      this.formData.operatorId = this.editData.operatorId
      this.title = '编辑账号'
    }

    this.optsOrigin.map(item1 => {
      this.opts.push({
        level: 1,
        id: item1.id,
        checked: false,
        label: item1.name,
        value: item1.departmentId
      })
      if (item1.children && item1.children.length > 0) {
        item1.children.map(item2 => {
          this.opts.push({
            level: 2,
            id: item2.id,
            checked: false,
            label: item2.name,
            value: item2.departmentId
          })

          if (item2.children && item2.children.length > 0) {
            item2.children.map(item3 => {
              this.opts.push({
                level: 3,
                id: item3.id,
                checked: false,
                label: item3.name,
                value: item3.departmentId
              })
            })
          }
        })
      }
    })
  },
  methods: {
    setChecked (bool) {
      bool && (this.opts = this.opts.map(item => {
        if (item.value === this.formData.departmentId) {
          item.checked = true
        } else {
          item.checked = false
        }
        return item
      }))
    },
    submit () {
      //  1.校验数据
      this.$refs.formData.validate(async valid => {
        if (valid) {
          // 验证通过
          // 2.组装数据
          if (!this.formData.password) {
            // this.formData.password = CryptoJS.MD5(this.formData.password).toString()
            delete this.formData.password
          }
          // 3.发送请求
          let res = null
          if (this.editData) {
            res = await this.$api.updateOperator(Object.assign(
              this.formData,
              this.formData.password ? { password: CryptoJS.MD5(this.formData.password).toString() } : {}))// 编辑
          } else {
            res = await this.$api.operatorSave(Object.assign(
              this.formData,
              this.formData.password ? { password: CryptoJS.MD5(this.formData.password).toString() } : {}))// 添加
          }
          if (res !== 'fail&&error') {
            this.$emit('close', true)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
