<template>
  <el-dialog
    width="32rem"
    :visible="true"
    top="8vh"
    :show-close="false"
    custom-class="them-dialog"
    v-loading="$store.state.requestLoading"
  >
    <div slot="title">
      数据权限
    </div>
    <el-form :model="formData" ref="formData" label-width="6rem" :rules="rules">
      <el-form-item label="权限类型:" prop="power">
        <el-select
          clearable
          size="medium"
          style="width: 18rem;"
          v-model="formData.power"
        >
          <el-option
            :key="index"
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in list"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if='formData.power===2'>
        <el-tree
          :data="data"
          show-checkbox
          node-key="departmentId"
          ref="tree"
          :default-expanded-keys="expandedKeys"
          :default-checked-keys="checkedKeys"
          @check-change="handleCheckChange"
          :props="defaultProps"
        >
        </el-tree>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    optsOrigin: {
      default: null,
      type: Array
    },
    dataRights: {
      default: null,
      type: Array
    },
    operatorIdOrigin: {
      default: null,
      type: String
    }
  },
  data () {
    return {
      formData: {
        power: '',
        operatorId: '',
        departmentIds: []
      },
      list: [
        {
          id: 1,
          label: '按组织架构',
          value: 1
        },
        {
          id: 2,
          label: '自定义数据权限',
          value: 2
        }
      ],
      data: [

      ],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      expandedKeys: [],
      checkedKeys: [],
      rules: { power: [{ required: true, message: '请选择权限', trigger: 'change' }] }
    }
  },
  created () {
    if (this.optsOrigin) {
      this.data = this.optsOrigin
      this.optsOrigin.map(item1 => {
        this.expandedKeys.push(item1.departmentId)
        if (item1.children && item1.children.length > 0) {
          item1.children.map(item2 => {
            this.expandedKeys.push(item2.departmentId)
            // this.opts.push({
            //   level: 2,
            //   id: item2.id,
            //   checked: false,
            //   label: item2.name,
            //   value: item2.departmentId
            // })

            if (item2.children && item2.children.length > 0) {
              item2.children.map(item3 => {
                this.expandedKeys.push(item3.departmentId)
                // this.opts.push({
                //   level: 3,
                //   id: item3.id,
                //   checked: false,
                //   label: item3.name,
                //   value: item3.departmentId
                // })
              })
            }
          })
        }
      })
    }
  },
  watch: {
    operatorIdOrigin: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.formData.operatorId = newValue
      }
    },
    dataRights: {
      // immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (newValue && newValue.length > 0) {
          this.formData.power = 2
        } else {
          this.formData.power = 1
        }
        this.checkedKeys = newValue
      }
    },
    'formData.power': {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (newValue === 2) {
          this.rules.power = [
            { required: true, message: '请选择权限', trigger: 'change' },
            {
              trigger: 'change',
              validator: (rule, value, callback) => {
                const result1 = this.getCheckedKeys()
                if (result1.length === 0) {
                  callback(new Error('至少选择一个部门'))
                  return
                }
                callback()
              }
            }
          ]
        } else {
          this.rules.power = [{ required: true, message: '请选择权限', trigger: 'change' }]
        }
      }
    }
  },
  methods: {
    submit () {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          if (this.formData.power === 2) {
            this.formData.departmentIds = this.getCheckedKeys()
          } else {
            this.formData.departmentIds = []
          }
          delete this.formData.power
          const res = await this.$api.saveRights(this.formData)// 编辑
          if (res !== 'fail&&error') {
            this.$message({
              type: 'success',
              message: '权限修改成功'
            })
            this.$emit('close', true)
          }
        }
      })
    },
    getCheckedKeys () {
      return this.$refs.tree.getCheckedKeys()
    },
    setCheckedKeys (data) {
      this.$refs.tree.setCheckedKeys(data)
    },
    resetChecked () {
      this.$refs.tree.setCheckedKeys([])
    },
    handleCheckChange () {
      this.$refs.formData.validate()
    }
  }
}
</script>

<style lang="scss" scoped></style>
