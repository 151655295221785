<template>
  <div class="container-page account-page">
    <div class="search-box">
      <el-form inline ref="searchForm" :model="searchForm">
        <el-form-item label="姓名" style="width: 10.8rem">
          <el-input
            size="small"
            placeholder="全部"
            style="width: 8rem"
            v-model="searchForm.name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="账号" style="width: 10.8rem">
          <el-input
            clearable
            size="small"
            placeholder="全部"
            style="width: 8rem"
            v-model="searchForm.phone"
          ></el-input>
        </el-form-item>

        <el-form-item label="部门" style="width: 10.8rem">
          <el-autocomplete
            size="small"
            style="width: 8rem"
            placeholder="请输入内容"
            v-model="searchForm.departmentName"
            :fetch-suggestions="querySearchAsync"
          ></el-autocomplete>

        </el-form-item>
        <el-form-item label="岗位" style="width: 10.8rem">
          <el-select
            style="width: 8rem"
            size="small"
            placeholder="全部"
            v-model="searchForm.dictId"
            clearable
          >
            <el-option
              v-for="(item, index) in jobs"
              :key="index"
              :label="item.dictValue"
              :value="item.dictId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" style="width: 10.8rem">
          <el-select
            style="width: 8rem"
            size="small"
            placeholder="全部"
            multiple
            v-model="searchForm.roleIdList"
          >
            <el-option
              v-for="(item, index) in roles"
              :key="index"
              :label="item.name"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" style="width: 10.8rem">
          <el-select
            style="width: 8rem"
            size="small"
            placeholder="全部"
            clearable
            v-model="searchForm.status"
          >
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="dialogName = 'add'"
          >添加</el-button
        >
        <form name="form1" style="display: inline-block; margin: 0 10px">
          <input
            type="file"
            id="uploads"
            :value="imgFile"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="xlsx"
            @change="uploadImg($event)"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-upload2"
            onclick="document.form1.uploads.click()"
            >批量导入</el-button
          >
        </form>
        <el-button type="primary" size="small" @click="openAccount"
          >批量启用</el-button
        >
        <el-button type="primary" size="small" @click="closeAccount"
          >批量停用</el-button
        >
        <el-button type="danger" size="small" @click="delAccount"
          >批量删除</el-button
        >
        <el-link
          type="primary"
          :underline="false"
          style="margin-left: 1rem; padding-top: 1rem"
          @click.native.prevent="download()"
          >点击下载批量导入模板</el-link
        >
        <el-link
          type="Info"
          style="
            margin-right: 2rem;
            padding-top: 1rem;
            float: right;
            text-decoration: underline;
          "
          @click.native.prevent="importRecord()"
          >批量导入记录</el-link
        >
      </div>
    </div>
    <el-table
      ref="tableData"
      :data="tableData"
      v-loading="$store.state.requestLoading"
      @selection-change="handleSelectionChange"
      style="
        border-left: 0.5px solid #ebeef5;
        border-right: 0.5px solid #ebeef5;
      "
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
        :selectable="selectInit"
      >
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="100"> </el-table-column>
      <el-table-column prop="phone" label="账号" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="departmentName" label="部门">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="dictName" label="岗位">
      </el-table-column>
      <el-table-column show-overflow-tooltip label="角色">
        <template slot-scope="scope">
          <span v-if="scope.row.role && scope.row.role.length > 0">
            <span v-for="(item, index) in scope.row.role" :key="index">
              {{
                index !== scope.row.role.length - 1
                  ? item.roleName + ","
                  : item.roleName + ""
              }}</span
            >
          </span>
          <span v-else-if="scope.row.type === 1">超级管理员</span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="160">
        <template slot-scope="scope">
          {{
            scope.row.createTime
              ? $util.dateFormat(scope.row.createTime)
              : $util.dateFormat(scope.row.createTime)
          }}
        </template>
      </el-table-column>
      <el-table-column width="80" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1" style="color: #3b9644">启用</span>
          <span v-else>停用</span>
        </template>
      </el-table-column>
      <el-table-column align=",center" width="320">
        <template slot-scope="scope" v-if="!scope.row.currentUser">
          <el-button size="mini" @click="editRow(scope.row.operatorId)"
            >编辑</el-button
          >
          <el-button size="mini" @click="setPower(scope.row.operatorId)"
            >数据权限</el-button
          >
          <el-button
            size="mini"
            @click="openOneAccount(scope.row.operatorId, scope.row.status)"
            >{{ scope.row.status === 1 ? "停用" : "启用" }}</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.status === 2"
            type="danger"
            @click="deleteOneAccount(scope.row.operatorId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :page-size="pager.pageSize"
        :total="pager.total"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    <addDialog
      v-if="dialogName === 'add'"
      @close="hideDialog"
      :editData="editData"
      :optsOrigin="opts"
      :jobs="jobs"
      :roles="roles"
    ></addDialog>
    <powerDialog
      v-if="dialogName === 'power'"
      @close="dialogName = ''"
      :optsOrigin="opts"
      :operatorIdOrigin="operatorIdEdit"
      :dataRights="dataRights"
    ></powerDialog>
  </div>
</template>
<script>
import addDialog from './addDialog.vue'
import powerDialog from './powerDialog.vue'
import axios from 'axios'
export default {
  components: {
    addDialog,
    powerDialog
  },
  data () {
    return {
      tableData: [],
      imgFile: '',
      searchForm: {
        name: '',
        phone: '',
        departmentName: '',
        dictId: '',
        roleIdList: [],
        status: ''
      },
      opts: [],
      optsFinall: [],
      jobs: [],
      roles: [],
      status: [
        { name: '启用', value: 1 },
        { name: '停用', value: 2 }
      ],
      operatorIds: [],
      dataRights: [],
      operatorIdEdit: '',
      pager: {
        total: 0,
        pageNo: 1,
        pageSize: 10
      },
      editData: null, // 编辑数据
      dialogName: '',
      multipleSelection: null

    }
  },
  created () {
    this.operatorList(
      Object.assign(this.searchForm, { pageNo: 1, pageSize: 10 })
    )
  },
  mounted () {
    if (this.tableData) {
      this.departmentList()
      this.getDictList()
      this.getAccountRoleList()
    }
  },

  methods: {

    querySearchAsync (queryString, cb) {
      var optsFinall = this.optsFinall
      var results = queryString ? optsFinall.filter(this.createStateFilter(queryString)) : optsFinall

      cb(results)
    },
    createStateFilter (queryString) {
      return (item) => {
        return (item.value.indexOf(queryString) === 0)
      }
    },

    // 根据条件禁止选择
    selectInit (row, index) {
      if (row.currentUser) {
        return false // 不可勾选
      } else {
        return true // 可勾选
      }
    },
    // 查询
    search () {
      this.operatorList(this.searchForm)
    },
    // 批量导入
    importAccount () {},
    // 批量启用
    openAccount () {
      if (!this.multipleSelection || this.multipleSelection.length === 0) {
        this.$message({
          message: '请选择账号',
          type: 'warning'
        })
      }
      this.multipleSelection.forEach((item) => {
        this.operatorIds.push(item.operatorId)
      })
      this.$confirm('此操作将启用账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api
          .updateStatus({ operatorIds: this.operatorIds, valid: true })
          .then((res) => {
            if (res !== 'fail&&error') {
              this.$message({
                type: 'success',
                message: '启用成功!'
              })
              this.operatorList()
              this.operatorIds = []
            }
          })
      })
    },
    // 批量停用
    closeAccount () {
      if (!this.multipleSelection || this.multipleSelection.length === 0) {
        this.$message({
          message: '请选择账号',
          type: 'warning'
        })
      }
      this.multipleSelection.forEach((item) => {
        this.operatorIds.push(item.operatorId)
      })
      this.$confirm('此操作将停用账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api
          .updateStatus({ operatorIds: this.operatorIds, valid: false })
          .then((res) => {
            if (res !== 'fail&&error') {
              this.$message({
                type: 'success',
                message: '停用成功!'
              })
              this.operatorList()
              this.operatorIds = []
            }
          })
      })
    },
    // 启用停用单个账号
    async openOneAccount (val, Bool) {
      this.operatorIds.push(val)
      const str = Bool === 1 ? '停用' : '启用'
      this.$confirm('此操作将' + str + '账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api
          .updateStatus({ operatorIds: this.operatorIds, valid: Bool !== 1 })
          .then((res) => {
            if (res !== 'fail&&error') {
              this.$message({
                type: 'success',
                message: str + '成功!'
              })
              this.operatorList()
              this.operatorIds = []
            }
          })
      })
    },
    // 删除单个账号
    async deleteOneAccount (val) {
      this.operatorIds.push(val)
      this.$confirm('此操作将删除账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api
          .deleteOperator({ operatorIds: this.operatorIds })
          .then((res) => {
            if (res !== 'fail&&error') {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.operatorList()
              this.operatorIds = []
            }
          })
      })
    },
    // 批量删除
    async delAccount () {
      if (!this.multipleSelection || this.multipleSelection.length === 0) {
        // this.$message({
        //   message: '请选择账号',
        //   type: 'warning'
        // })
        this.$confirm('请选择要删除的账号', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        })
          .then(() => {})
          .catch(() => {})
        return
      }
      // operatorIds
      this.multipleSelection.forEach((item) => {
        this.operatorIds.push(item.operatorId)
      })
      this.$confirm('此操作将删除账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api
          .deleteOperator({ operatorIds: this.operatorIds })
          .then((res) => {
            if (res !== 'fail&&error') {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.operatorList()
              this.operatorIds = []
            } else {
              this.operatorList()
            }
          })
      })
    },
    // 处理选中
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 编辑
    async editRow (operatorId) {
      const res = await this.$api.detailOperator({
        operatorId: operatorId
      })
      if (res !== 'fail&&error') {
        this.dialogName = 'add'
        this.editData = res
      }
    },
    // 设置数据权限
    async setPower (row) {
      this.dialogName = 'power'
      const res = await this.$api.dataRights({ operatorId: row })
      this.operatorIdEdit = row
      if (res !== 'fail&&error') {
        this.dataRights = res
      }
    },
    // 删除
    handleDelete () {},
    // 账号列表查询
    async operatorList () {
      const res = await this.$api.pageOperator(this.searchForm)
      if (res !== 'fail&&error') {
        this.tableData = res.data
        this.pager.total = res.total
      }
    },
    // 分页查询
    currentChange (pageNo) {
      this.pager.pageNo = pageNo
      this.operatorList(
        Object.assign(this.searchForm, { pageNo: this.pager.pageNo })
      )
    },
    // 关闭弹窗
    hideDialog (bool) {
      this.dialogName = ''
      this.editData = null
      if (bool) {
        this.operatorList()
      }
    },
    // 全部部门列表查询
    async departmentList () {
      const res = await this.$api.departmentList({})
      if (res !== 'fail&&error') {
        this.opts = res
        this.opts.map((item1) => {
          this.optsFinall.push({
            value: item1.name
          })
          if (item1.children && item1.children.length > 0) {
            item1.children.map((item2) => {
              this.optsFinall.push({
                value: item2.name
              })

              if (item2.children && item2.children.length > 0) {
                item2.children.map((item3) => {
                  this.optsFinall.push({
                    value: item3.name
                  })
                })
              }
            })
          }
        })
      }
    },
    // 全部岗位列表查询
    async getDictList () {
      const res = await this.$api.getDictList({ dictType: 'position' })
      if (res !== 'fail&&error') {
        this.jobs = res
      }
    },
    // 全部角色列表查询
    async getAccountRoleList () {
      const res = await this.$api.getAccountRoleList({})
      if (res !== 'fail&&error') {
        this.roles = res
      }
    },
    download () {
      const domain = window.location.host
      window.location.href =
        'https://' +
        domain +
        '/api/tenant/tenant-oms/v1/operator/download-template'
    },
    uploadImg (e) {
      if (!e.target.files[0]) {
        return
      }
      this.$store.state.requestLoading = true
      const fileData = new FormData()
      fileData.append('file', e.target.files[0])
      axios
        .post('/apiEx/tenant/tenant-oms/v1/operator/import', fileData, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        .then((resp) => {
          if (resp.data.code === 0) {
            this.$message({
              type: 'success',
              message: '批量导入成功!'
            })
            this.operatorList()
          } else {
            this.$message.error(resp.data.message)
          }
          this.$store.state.requestLoading = false
          var obj = document.getElementById('uploads')
          obj.value = null
        })
        .catch((error) => {
          this.$store.state.requestLoading = false
          this.$message.error(
            '后台导入耗时较久，您可以继续进行其他操作，可通过右上角 批量导入记录查看导入进度。'
          )
          console.log(error)
        })
    },
    // 批量导入记录
    importRecord () {
      const key = '/system/setting/account/record'
      const index = this.$store.state.tab.tabArray.findIndex(
        (item) => item.router === key
      )
      if (index === -1) {
        this.$store.commit('tabAdd', {
          router: key,
          name: '批量导入记录'
        })
      } else {
        this.$store.commit('setActiveIndex', index)
        this.$parent.scrollTab(index)
      }
      this.$router.push('/system/setting/account/record')
    }
  }
}
</script>

<style lang="scss" scoped>
.account-page {
  .search-box {
    border: 0.5px solid #ebeef5;
    padding: 0.66rem 1.2rem 0.88rem;
    .el-form-item {
      margin-bottom: 0.66rem;
    }
  }
  .pager {
    text-align: right;
    padding: 0.66rem 0 0;
  }

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    height: 12px;
    cursor: pointer;
    background-color: #6b6b6b5e;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgba(88, 87, 87, 0.219);
  }
}
</style>
